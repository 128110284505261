/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import VideoExplanationComponent from '../../../components/VideoExplanationComponent';

const ResourcesPhysics = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Physics for NEET & JEE</h1>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <h2>Physics I</h2>
                    <ul>
<li>This page is under construction.</li>
<li>Please check back soon!</li></ul>
                </Container>
            </div>
		</div>
	);
};

export default ResourcesPhysics;
