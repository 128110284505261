/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Form } from "react-bootstrap";
import moment from "moment/moment";
import Loader from "../../components/Loader";
import QuestionSetContainer from "../../components/PracticeSetComponents/QuestionSetContainer";
import BannerImage from "../../assets/images/banner-image.png";
import {
  practiceSets,
  checkTimedSwitchAPI,
  adminPracticeSets,
  getOndemandPracticeJson,
  storePracticeData,
  getUserProfile,
  getOndemandAdaptiveCount,
  adminTimedSaveAsDraft,
  timedSaveAsDraft,
  getAdminTimedSaveAsDraftData,
  getSingleGradesOndemandAdaptive,
  practiceSectionsOndemandAdaptive,
  paymentSessionCreate,
} from "../../redux/actions";
import "./styles.scss";
import CustomModal from "../../components/CustomModal";
import Accordion from "react-bootstrap/Accordion";
import timeIcon from "../../assets/images/icons/timer-icon.svg";
import crownIcon from "../../assets/images/icons/crown.png";
import starIcon from "../../assets/images/star.png";
import MyVerticallyCenteredModal from "./verticalCenterModal";
import OnDemandCenteredModal from "./demandCenterModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import UserPracticePage from "./userPracticePage";
import AdminPracticePage from "./adminPracticePage";

const PracticePage = () => {
  const [modalShowAdaptive, setModalShowAdaptive] = useState(false);
  const [modalShowOnDemand, setModalShowOnDemand] = useState(false);
  const [sidid, setSidid] = useState(null);
  const [mques, setMques] = useState(0);
  const [mhours, setMhours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [gaphours, setGaphours] = useState(0);
  const [userparams, setUserparams] = useState(null);
  const [dataset, setDataset] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [timeHours, setTimeHours] = useState(0);
  const [timeMin, setTimeMin] = useState(0);
  const [qCount, setQCount] = useState(null);
  const [includeTimed, setIncludeTimed] = useState(true);
  const [mustTimed, setMustTimed] = useState(false);
  const [url, setUrl] = useState("");
  const [questionSetId, setQuestionSetId] = useState("");
  const [practiceUrl, setPracticeUrl] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [testDateTime, setTestDateTime] = useState("");
  const [unAuthorizedTimeSectionShow, setUnAuthorizedTimeSectionShow] =
    useState(false);
  const [titleset, setTitleset] = useState("");
  const [givenTest, setGivenTest] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const shouldrender = useRef(true);
  const [testName, setTestName] = useState("");

  const {
    loading,
    practiceSetData,
    practiceSetsErrorMessage,
    ondemandPracticeJson,
    onAdtestCounts,
  } = useSelector((state) => state.practiceSet);
  const {
    loading: authLoading,
    isLoggedIn,
    userData,
  } = useSelector((state) => state.auth);
  const location = useLocation();
  const isAdmin =
    userData?.payload?.is_admin &&
    location.pathname.startsWith("/admin/practice");

  const userDetails = useSelector((state) => state.profile);
  const {
    paymentSessionUrl,
    stopAutoRecurringSuccess,
    stopAutoRecurringError,
  } = useSelector((state) => state.payment);
  useEffect(() => {
    setLoadData(true);
    const fetchPracticeSets = async () => {
      if (isLoggedIn) {
        dispatch(paymentSessionCreate());
      }
      localStorage.removeItem("enable_non_timmer_draft");
      await dispatch(isAdmin ? adminPracticeSets() : practiceSets());
    };
    fetchPracticeSets();
  }, [isLoggedIn]);

  useEffect(() => {
    if (shouldrender.current && userData.payload) {
      shouldrender.current = false;
      dispatch(getUserProfile())
        .unwrap()
        .then((res) => {
          dispatch(
            getOndemandAdaptiveCount({
              username: res?.user_data?.username,
              email: res?.user_data?.email,
              userId: res?.user_data?._id,
            })
          );
        });
    }
  }, [userData]);

  const handleLoginCloseModal = () => {
    setLoginModalShow(false);
  };
  const navigateLoginPage = () => {
    if (userData.payload) {
      window.location.href = paymentSessionUrl;
    } else {
      navigate("/login");
    }
  };
  const modalTitle = userData.payload ? "Upgrade to Premium" : "Login";
  const modalContent = (
    <p>
      {userData.payload
        ? "This is for Premium/Special1 users"
        : "You have to login first to practice this test!"}
    </p>
  );
  const modalFooterButtons = [
    { label: "Close", variant: "danger", onClick: handleLoginCloseModal },
    {
      label: userData.payload ? "upgrade to premium" : "Login",
      variant: "primary",
      onClick: navigateLoginPage,
    },
  ];

  const handleClick = (
    url,
    setId,
    isTimedQuestion,
    isSubmitted,
    timeHrs,
    timeMins,
    qCount,
    isMustTimed,
    setname,
    list
  ) => {
    if (userData.payload || isTimedQuestion === null) {
      localStorage.setItem("set-id", setId);
      const practiceUrl = isAdmin
        ? `/admin${url}?id=${setId}`
        : `${url}?id=${setId}`;
      const timedUrl = isAdmin
        ? `/admin/practice/timed/setId?id=${setId}`
        : `/practice/timed/setId?id=${setId}`;

      setQuestionSetId(setId);
      setPracticeUrl(url);
      setMustTimed(isMustTimed);
      dispatch(
        storePracticeData({
          type: "Weekly Practice",
          setname: setname,
          list: list,
          practiceType: "weekly",
        })
      );

      if (isTimedQuestion) {
        if (isLoggedIn) {
          if (isSubmitted) {
            navigate(practiceUrl);
          } else {
            setShow(true);
            setTimeHours(timeHrs);
            setTimeMin(timeMins);
            setQCount(qCount);
            setUrl(timedUrl);
          }
        } else {
          setUnAuthorizedTimeSectionShow(true);
        }
      } else {
        navigate(practiceUrl);
      }
    } else {
      setLoginModalShow(true);
    }
  };

  const PracticeMainPage = () => {
    const [draftStatus, setDraftStatus] = useState({});

    // useEffect(() => {
    //   if (isAdmin && practiceSetData?.data) {
    //     practiceSetData.data.forEach((item) => {
    //       item.set.forEach((list) => {
    //         checkDraftSavedTest(list.set_id);
    //       });
    //     });
    //   }
    // }, [practiceSetData]);

    // const checkDraftSavedTest = async (qset_Id) => {
    //   try {
    //     const res = await dispatch(
    //       getAdminTimedSaveAsDraftData(qset_Id)
    //     ).unwrap();
    //     setDraftStatus((prevStatus) => ({
    //       ...prevStatus,
    //       [qset_Id]: !!res.data,
    //     }));
    //   } catch (error) {
    //     console.error("Error checking draft saved status:", error);
    //   }
    // };
    if (loading || authLoading) {
      return (
        <div className="loader_section">
          <Loader />
        </div>
      );
    }

    if (practiceSetsErrorMessage) {
      return (
        <div>
          <p className="error_message">{practiceSetsErrorMessage}</p>
        </div>
      );
    }

    if (!(practiceSetData?.data?.length > 0)) return;

    const authorizedTimeSectionModal = () => {
      if (isLoggedIn) {
        navigate("/pricing");
      } else {
        navigate("/login");
      }
    };

    const handleTimedQuestionChange = (e) => {
      //draft save
      if (e.target.checked === false) {
        localStorage.setItem("enable_non_timmer_draft", true);
      } else {
        localStorage.removeItem("enable_non_timmer_draft");
      }
      setIncludeTimed(e.target.checked);
      if (e.target.checked) {
        setUrl(
          isAdmin
            ? `/admin/practice/timed/setId?id=${questionSetId}`
            : `/practice/timed/setId?id=${questionSetId}`
        );
      } else {
        setUrl(
          isAdmin
            ? `/admin${practiceUrl}?id=${questionSetId}`
            : `${practiceUrl}?id=${questionSetId}`
        );
      }
    };

    const closeAuthorizedTimeSectionModal = () =>
      setUnAuthorizedTimeSectionShow(false);

    const modalTitle = `${isLoggedIn ? "Upgrade Plan" : "Login"}`;
    const modalContent = (
      <p>Timed practice is available only for logged-in users.</p>
    );
    const modalFooterButtons = [
      {
        label: "Cancel",
        variant: "link",
        onClick: closeAuthorizedTimeSectionModal,
      },
      {
        label: isLoggedIn ? "Show Pricing" : "Login",
        variant: "primary",
        onClick: authorizedTimeSectionModal,
      },
    ];

    const timedModalTitle = "Let's get started!";
    const timedModalContent = (
      <>
        <div className="timed_modal_info_wrap">
          <div className="timed_modal_info">
            <div className="timed_modal_info_box">
              <h4>{`${qCount}`}</h4>
              <h5>Questions</h5>
            </div>
            {includeTimed ? (
              <div className="timed_modal_info_box">
                <h4>
                  {moment({ hour: timeHours, minute: timeMin }).format("HH:mm")}
                </h4>
                <h5>Hours</h5>
              </div>
            ) : (
              <div className="infinity_time">
                <h4>&infin;</h4>
                <h5>Complete by Sunday</h5>
              </div>
            )}
          </div>
        </div>
        <div className="timed_modal_rules">
          {mustTimed ? (
            <>
              <span>Can't finish in one sitting?</span>
              <div>
                Before starting you can switch between timed and not-timed.
              </div>
              <div className="switch_checkbox_part">
                <div className="switch_part">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={
                      includeTimed
                        ? "Timed (must complete in one sitting)"
                        : "Not timed (complete by Sunday)"
                    }
                    reverse
                    value={includeTimed}
                    checked={includeTimed}
                    onChange={handleTimedQuestionChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <span>Must complete in one sitting.</span>
          )}
        </div>
      </>
    );
    const closeTimedModal = () => {
      setShow(false);
      setIncludeTimed(true);
    };
    const timedModalFooterButtons = [
      // {
      // 	label: 'Start later',
      // 	variant: 'link',
      // 	onClick: closeTimedModal
      // },
      {
        label: "Start now",
        variant: "primary",
        onClick: () => checkTimedSwitch(url),
      },
    ];

    const checkTimedSwitch = (url) => {
      if (includeTimed) {
        navigate(url);
      } else {
        const idValue = new URLSearchParams(url.split("?")[1]).get("id");
        const data = {
          set_id: idValue,
        };
        dispatch(checkTimedSwitchAPI(data));
        navigate(url);
      }
    };

    const handleOndemand = (list, item) => {
      list.set_name ? setTestName(list.set_name) : setTestName(list.sid);
      if (userData.payload) {
        const is_basic_user =
          ["free", "Free", "Basic", "basic"].includes(
            userDetails?.subscription_type
          ) ||
          ["free", "Free", "basic", "Basic"].includes(
            userDetails.userData?.subscription_type
          );
        if (is_basic_user) {
          setLoginModalShow(true);
        } else {
          localStorage.setItem("maxQues", list.rd_maxnoq);
          localStorage.setItem("set-id", list.set_id ? list.set_id : list.id);
          setTitleset(item?.cat_title);
          setModalShowOnDemand(true);
          setSidid(list?.sid);
          setMques(list?.rd_maxnoq);
          setMhours(list?.rd_time_hrs);
          setMinutes(list?.rd_time_mins);
          setGaphours(list?.rd_time_gap_hours);
          setUserparams(list?.rd_user_params);
          setDataset(item?.set);
          dispatch(
            storePracticeData({
              type: "On-demand Practice",
              setname: list.set_name_short ? list.set_name_short : list.sid,
              list: list,
              practiceType: "ondemand",
            })
          );
        }
      } else {
        setLoginModalShow(true);
      }
    };

    const handleAdaptive = (list, item) => {
      list.set_name ? setTestName(list.set_name) : setTestName(list.sid);
      if (userData.payload) {
        const is_basic_user =
          ["free", "Free", "Basic", "basic"].includes(
            userDetails?.subscription_type
          ) ||
          ["free", "Free", "basic", "Basic"].includes(
            userDetails.userData?.subscription_type
          );
        if (is_basic_user) {
          setLoginModalShow(true);
        } else {
          localStorage.setItem("maxQues", list.ad_maxnoq);
          localStorage.setItem("set-id", list.set_id ? list.set_id : list.id);
          setTitleset(item?.cat_title);
          setModalShowAdaptive(true);
          setSidid(list?.sid);
          setMques(list?.ad_maxnoq);
          setMhours(list?.ad_time_hrs);
          setMinutes(list?.ad_time_mins);
          setGaphours(list?.ad_time_gap_hours);
          setUserparams(list?.ad_user_params);
          setDataset(item?.set);
          dispatch(
            storePracticeData({
              type: "Adaptive Practice",
              setname: list.set_name_short ? list.set_name_short : list.sid,
              list: list,
              practiceType: "adaptive",
            })
          );
        }
      } else {
        setLoginModalShow(true);
      }
    };

    const handleShowCount = (user, sid, setTitle, testType) => {
      const countItem = onAdtestCounts?.data?.find(
        (item) =>
          item?.user === user &&
          item?.sid === sid &&
          item?.setTitle === setTitle &&
          item?.testType === testType
      );
      return countItem?.count;
    };

    const handleCheckTestDone = (user, sid, setTitle, testType) => {
      const countItem = onAdtestCounts?.data?.find(
        (item) =>
          item?.user === user &&
          item?.sid === sid &&
          item?.setTitle === setTitle &&
          item?.testType === testType
      );
      if (countItem) {
        const startDate = new Date(countItem?.startDate);
        const currentDate = new Date();
        const diffInHours = (currentDate - startDate) / (1000 * 60 * 60);
        if (diffInHours >= 48) {
          setGivenTest(true);
        } else if (countItem?.startDate === undefined) {
          setGivenTest(true);
        } else {
          setTestDateTime(countItem?.startDate);
          setGivenTest(false);
        }
      } else {
        setGivenTest(true);
      }
    };

    const handleLatestTest = (url, setId, parseData, id) => {
      const data = {
        created_at: "",
        draft: { attempted: false },
        enumerate_qs: parseData?.q_set?.enumerate_qs,
        id: id,
        s3_base_url: "",
        set_description: parseData?.q_set?.set_description,
        set_id: parseData?.q_set?.set_id,
        set_name: parseData?.q_set?.set_name,
        submitted: {
          attempted: true,
          data: {
            total_noq_notgraded: parseData?.g_set?.total_noq_notgraded ?? null,
            total_score: parseData?.g_set?.total_score,
            total_score_outof: parseData?.g_set?.total_score_outof,
            total_score_pct: parseData?.g_set?.total_score_pct,
          },
        },
        subscription_type: "",
        time_hrs: parseData?.q_set?.time_hrs,
        time_mins: parseData?.q_set?.time_mins,
        timed: parseData?.q_set?.timed,
        list_sections: [
          {
            access_type: parseData?.q_set?.list_sections?.[0]?.access_type,
            section_noq_notgraded:
              parseData?.g_set?.list_sections?.[0]?.section_noq_attempted,
            section_score: parseData?.g_set?.list_sections?.[0]?.section_score,
            section_score_outof:
              parseData?.g_set?.list_sections?.[0]?.section_score_outof,
            section_title: parseData?.q_set?.list_sections?.[0]?.section_title,
            sno: parseData?.q_set?.list_sections?.[0]?.sno,
            list_qgs: [
              {
                description:
                  parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]
                    ?.description,
                gr_type:
                  parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.gr_type,
                group_title:
                  parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]
                    ?.group_title,
                notes:
                  parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.notes,
                tags: parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.tags,
                list_ques:
                  parseData?.q_set?.list_sections?.[0]?.list_qgs?.[0]?.list_ques?.map(
                    (item, index) => ({
                      ...item,
                      attempted_obj:
                        parseData?.a_set?.list_sections?.[0]?.list_ags?.[0]
                          ?.list_ans?.[index]?.stud_ans,
                      ans_obj:
                        parseData?.g_set?.list_sections?.[0]?.list_ggs?.[0]
                          ?.list_graded[index],
                    })
                  ),
              },
            ],
          },
        ],
      };
      dispatch(practiceSectionsOndemandAdaptive(data));
      const practiceUrl = isAdmin
        ? `/admin${url}/${setId}?sid=${setId}`
        : `${url}/${setId}?sid=${setId}`;
      navigate(practiceUrl);
    };

    const getSingleTestDataOndemandAdaptive = (practice_type, sid) => {
      const obJData = {
        practice_type: practice_type,
        sid: sid,
        is_admin: isAdmin ? true : false,
      };
      dispatch(getSingleGradesOndemandAdaptive(obJData))
        .unwrap()
        .then((res) => {
          const parseData = JSON.parse(res?.data?.end_result?.data);
          handleLatestTest(
            `/practice`,
            res.data.sid,
            parseData,
            res?.data?._id
          );
        });
    };

    return (
      <div>
        <section className="banner_section">
          <Container>
            <div className="banner_wrapper">
              <div className="banner_content">
                <h1>Practice Sets</h1>
                <h2>Click on a question-set below to practice.</h2>
                <p>
                  Questions are typically updated every <strong>Monday</strong>.{" "}
                  <u>
                    Submit your work for grading by the following{" "}
                    <strong>Sunday midnight</strong> (US Pacific Time).
                  </u>
                </p>
                <p>
                  <mark>If you encounter any issues or have feedback,</mark>{" "}
                  please <a href="mailto:boo9info@gmail.com">email me</a>. Thank you!
                  - Tikka
                </p>
              </div>
              <img src={BannerImage} alt="banner" className="banner_image" />
            </div>
            <div className="down-arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Container>
        </section>
        <section className="questions_set_section" id="question-section22">
          {/* <Container>
            {![
              "guest1",
              "guest2",
              "guest3",
              "yutytyranus",
              "Yutytyranus",
              "devexhub2",
            ].includes(userData?.payload?.username) &&
              loadData &&
              practiceSetData?.data?.map((item, index) => {
                return userData?.payload?.is_admin &&
                  location.pathname.startsWith("/admin/practice") ? null : (
                  <QuestionSetContainer
                    key={index}
                    item={item}
                    handleClick={handleClick}
                  />
                );
              })}
          </Container> */}

          <CustomModal
            title={modalTitle}
            content={modalContent}
            footerButtons={modalFooterButtons}
            show={unAuthorizedTimeSectionShow}
            handleClose={closeAuthorizedTimeSectionModal}
          />
          <CustomModal
            className={"count_down_time_modal"}
            title={timedModalTitle}
            content={timedModalContent}
            footerButtons={timedModalFooterButtons}
            show={show}
            handleClose={closeTimedModal}
          />
        </section>
        {/* =======NEW DESIGNS BELOW======= */}
        {userData?.payload?.is_admin &&
        location.pathname.startsWith("/admin/practice") ? (
          <AdminPracticePage
            loadData={loadData}
            practiceSetData={practiceSetData}
            handleClick={handleClick}
            handleShowCount={handleShowCount}
            handleOndemand={handleOndemand}
            handleCheckTestDone={handleCheckTestDone}
            handleAdaptive={handleAdaptive}
            draftStatus={draftStatus}
            getSingleTestDataOndemandAdaptive={
              getSingleTestDataOndemandAdaptive
            }
          />
        ) : (
          <UserPracticePage
            loadData={loadData}
            practiceSetData={practiceSetData}
            handleClick={handleClick}
            handleShowCount={handleShowCount}
            handleOndemand={handleOndemand}
            handleCheckTestDone={handleCheckTestDone}
            handleAdaptive={handleAdaptive}
            getSingleTestDataOndemandAdaptive={
              getSingleTestDataOndemandAdaptive
            }
          />
        )}

        {/* WEEKLY POPUP BELOW */}
        {dataset ? (
          <MyVerticallyCenteredModal
            show={modalShowAdaptive}
            onHide={() => setModalShowAdaptive(false)}
            sidid={sidid}
            mques={mques}
            mhours={mhours}
            minutes={minutes}
            gaphours={gaphours}
            userparams={userparams}
            dataset={dataset}
            handleClick={handleClick}
            titleset={titleset}
            givenTest={givenTest}
            testDateTime={testDateTime}
            testName={testName}
          />
        ) : null}

        {/* ONDEMAND POPUP BELOW */}
        {dataset ? (
          <OnDemandCenteredModal
            show={modalShowOnDemand}
            onHide={() => setModalShowOnDemand(false)}
            sidid={sidid}
            mques={mques}
            mhours={mhours}
            minutes={minutes}
            gaphours={gaphours}
            userparams={userparams}
            dataset={dataset}
            handleClick={handleClick}
            titleset={titleset}
            givenTest={givenTest}
            testDateTime={testDateTime}
            testName={testName}
          />
        ) : null}
      </div>
    );
  };

  const handlePracticePageClass = () => {
    if (practiceSetsErrorMessage) return "practice-error";

    return "practice_page";
  };

  return (
    <div className={handlePracticePageClass()}>
      {PracticeMainPage()}
      <CustomModal
        size={"md"}
        title={modalTitle}
        content={modalContent}
        footerButtons={modalFooterButtons}
        show={loginModalShow}
        handleClose={handleLoginCloseModal}
      />
    </div>
  );
};

export default PracticePage;
