/* eslint-disable init-declarations */
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { isEmpty, has, isNull, isArray } from "lodash";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import Timer from "../../components/PracticeSectionComponents/Timer";
import { useSpeechSynthesis } from "react-speech-kit";
import TextAnswerQ from "../../components/QuestionsGroup/TextAnswerQ";
import DictationQ from "../../components/QuestionsGroup/DictationQ";
import MathExpressionQ from "../../components/QuestionsGroup/MathExpressionQ";
import MultipleChoiceQ from "../../components/QuestionsGroup/MultipleChoiceQ";
import UniqueChoiceQ from "../../components/QuestionsGroup/UniqueChoiceQ";
import TrueFalseQ from "../../components/QuestionsGroup/TrueFalseQ";
import FillGroupQ from "../../components/QuestionsGroup/FillGroupQ";
import FillInlineQ from "../../components/QuestionsGroup/FillInlineQ";
import FillBlankQ from "../../components/QuestionsGroup/FillBlankQ";
import FillImgQ from "../../components/QuestionsGroup/FillImgQ";
import MatchItemsQ from "../../components/QuestionsGroup/MatchItemsQ";
import MatrixMatchQ from "../../components/QuestionsGroup/MatrixMatchQ";
import ErrorHandleComponent from "../../components/ErrorHandleComponent";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";
import infoIcon from "../../assets/images/icons/info.svg";
import {
  getQuestionIndex,
  getFieldVal,
  getQuestionNumber,
} from "../../utils/PracticePage";
import nextIcon from "../../assets/images/icons/next-icon.svg";
import prevIcon from "../../assets/images/icons/prev-icon.svg";
import exitIcon from "../../assets/images/icons/exit-icon.svg";
import warningIcon from "../../assets/images/icons/warning-icon.svg";
import {
  practiceSections,
  handleCounterGet,
  handleCounterPost,
  timedSaveAsDraft,
  getTimedSaveAsDraftData,
  practiceSectionsSubmit,
  adminPracticeSections,
  adminPracticeSectionsSubmit,
  handleAdminCounterGet,
  handleAdminCounterPost,
  adminTimedSaveAsDraft,
  getAdminTimedSaveAsDraftData,
  getFlaggedQuestion,
  storeCurrentQuestionAns,
} from "../../redux/actions";
import { resetFormData } from "../../redux/slices/practiceSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import FlaggedQuestionModal from "../PracticePage/flaggedQuestionPage";

const TimedSectionPage = () => {
  const navigate = useNavigate();
  const shouldRender = useRef(true);
  const shouldSaveDataAsDraft = useRef(true);
  const dispatch = useDispatch();
  const startTime = useRef(null);
  const questionIndex = useRef({ i: null, j: null, k: null });
  const [show, setShow] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [id, setId] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingForResponse, setLoadingForResponse] = useState(true);
  const [submittedSectionModal, setSubmittedSectionModal] = useState(false);
  const [qList, setQList] = useState([]);
  const [postCounterCall, setPostCounterCall] = useState(false);
  const [warningShow, setWarningShow] = useState(false);
  const [markForReviewQuestions, setMarkForReviewQuestions] = useState([]);
  const [closetimer, setClosetimer] = useState(true);
  const [closewarntimer, setClosewarntimer] = useState(false);
  const [sumbitMode, setSubmitMode] = useState("clicked-submit");
  const [flagQuestion, setFlagQuestion] = useState(false);

  const qSetId = new URLSearchParams(location.search).get("id");

  const { userData } = useSelector((state) => state.auth);

  const {
    loading,
    practiceSectionData: questionsList,
    alertSuccessMessage,
    alertErrorMessage,
    counterResponse,
    counterPostResponse,
    getTimedSaveAsDraftDataResponse,
    timedSectionLoading,
    setStore,
    flagQuestionModal,
    currentQuestion,
  } = useSelector((state) => state.practiceSet);

  const isAdmin =
    userData?.payload?.is_admin &&
    location.pathname.startsWith("/admin/practice");

  useEffect(() => {
    const beforeunloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", beforeunloadCallback);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href);
      values && setShow(true);
    });
    return () => {
      window.removeEventListener("beforeunload", beforeunloadCallback, false);
    };
  }, []);

  useEffect(() => {
    if (shouldRender.current) {
      dispatch(
        isAdmin ? handleAdminCounterGet(qSetId) : handleCounterGet(qSetId)
      );
      shouldRender.current = false;
      return () => {
        dispatch(resetFormData());
      };
    }
  }, []);

  useEffect(() => {
    const fetchPracticeSections = (qSetId) => {
      dispatch(
        isAdmin ? adminPracticeSections(qSetId) : practiceSections(qSetId)
      );
    };
    if (counterResponse?.data?.count === 0) {
      fetchPracticeSections(qSetId);
      dispatch(
        isAdmin
          ? handleAdminCounterPost({ set_id: qSetId, count: 1 })
          : handleCounterPost({ set_id: qSetId, count: 1 })
      );
      setPostCounterCall(true);
      return () => {
        dispatch(resetFormData());
      };
    }
    if (counterResponse?.data?.count > 0) {
      dispatch(
        isAdmin
          ? getAdminTimedSaveAsDraftData(qSetId)
          : getTimedSaveAsDraftData(qSetId)
      ).then((res) => {
        if (res.payload === "No data found!") {
          setSubmittedSectionModal(true);
        }
      });
    }
  }, [counterResponse]);

  useEffect(() => {
    if (
      getTimedSaveAsDraftDataResponse &&
      getTimedSaveAsDraftDataResponse.status_code === 200
    ) {
      dispatch(
        isAdmin
          ? adminPracticeSectionsSubmit(getTimedSaveAsDraftDataResponse.data)
          : practiceSectionsSubmit(getTimedSaveAsDraftDataResponse.data)
      );
      setSubmittedSectionModal(true);
    }
  }, [getTimedSaveAsDraftDataResponse]);

  useEffect(() => {
    if (postCounterCall) {
      if (counterPostResponse && counterPostResponse.status_code === 200) {
        setPostCounterCall(false);
      } else if (counterPostResponse.error) {
        setPostCounterCall(false);
        toast.error(counterPostResponse.message);
      }
    }
  }, [counterPostResponse]);

  const [isCall, setIsCall] = useState(false);

  useEffect(() => {
    if (isCall) {
      if (alertSuccessMessage && alertSuccessMessage.status_code === 200) {
        setIsCall(false);
        navigate(isAdmin ? "/admin/practice" : "/practice");
      } else if (alertErrorMessage) {
        setIsCall(false);
        toast.error(alertErrorMessage);
      }
    }
  }, [alertSuccessMessage, alertErrorMessage]);

  const timeStamp = (hours, minutes) => {
    return (hours * 3600 + minutes * 60) * 1000;
  };

  const onSubmit = (values) => {
    setIsCall(true);
    localStorage.removeItem("time");
    localStorage.removeItem("save_draft");
    const totalTime = Date.now() - startTime.current;
    const { i, j, k } = questionIndex.current;

    const temp = values.list_sections[i].list_ags[j].list_ans[k].list_times;
    const tempListStarts =
      values.list_sections[i].list_ags[j].list_ans[k].list_starts;

    values.list_sections[i].list_ags[j].list_ans[k].list_times = [
      ...temp,
      totalTime,
    ];
    values.list_sections[i].list_ags[j].list_ans[k].list_starts = [
      ...tempListStarts,
      moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
    ];

    setShow(false);
    for (let i = 0; i < values.list_sections.length; i++) {
      const arr = Object.values(values.list_sections[i].dict_qno_attempted);
      values.list_sections[i].noq_attempted = 0;
      values.list_sections[i].noq_total = arr.length;
      for (let j = 0; j < arr.length; j++) {
        if (arr[j] === true) {
          values.list_sections[i].noq_attempted += 1;
        }
      }
    }
    if (isSubmit) {
      values.clicked_submit = true;
    } else values.clicked_submit = false;
    dispatch(
      isAdmin
        ? adminPracticeSectionsSubmit(values)
        : practiceSectionsSubmit(values)
    );
  };

  const onSaveAsDraft = () => {
    const totalTime = Date.now() - startTime.current;
    startTime.current = Date.now();
    const { i, j, k } = questionIndex.current;
    const valuesForDraft = values;

    const temp =
      valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_times;
    const tempListStarts =
      valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_starts;

    valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_times = [
      ...temp,
      totalTime,
    ];
    valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_starts = [
      ...tempListStarts,
      moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
    ];

    for (let i = 0; i < valuesForDraft.list_sections.length; i++) {
      const arr = Object.values(
        valuesForDraft.list_sections[i].dict_qno_attempted
      );
      valuesForDraft.list_sections[i].noq_attempted = 0;
      valuesForDraft.list_sections[i].noq_total = arr.length;
      for (let j = 0; j < arr.length; j++) {
        if (arr[j] === true) {
          valuesForDraft.list_sections[i].noq_attempted += 1;
        }
      }
    }
    valuesForDraft.clicked_submit = false;
    setValues(valuesForDraft);
    dispatch(
      isAdmin
        ? adminTimedSaveAsDraft(valuesForDraft)
        : timedSaveAsDraft(valuesForDraft)
    );
  };

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {},
      onSubmit,
    });

  useEffect(() => {
    if (isEmpty(questionsList)) return;
    startTime.current = Date.now();
    if (!localStorage.getItem("time") && questionsList) {
      localStorage.setItem(
        "time",
        Date.now() + timeStamp(questionsList.time_hrs, questionsList.time_mins)
      );
    }
    if (questionsList && questionsList.error && isEmpty(questionsList)) {
      setLoadingForResponse(false);
    }

    const temp = questionsList.list_sections
      .map((e) => {
        return e.list_qgs.map((element) => {
          return element.list_ques;
        });
      })
      .flat()
      .flat();

    setQList(temp);
    setMarkForReviewQuestions(
      temp?.map((ele, index) => {
        return { id: index, checked: false };
      })
    );

    setFieldValue("qset_id", `${questionsList.set_id}`);
    setFieldValue("clicked_submit", null);

    for (let i = 0; i < questionsList.list_sections.length; i++) {
      const noqAttempted = questionsList.list_sections[i].noq_attempted;
      const noqTotal = questionsList.list_sections[i].noq_total;

      setFieldValue(`list_sections.${i}.noq_attempted`, 0);

      if (noqAttempted) {
        setFieldValue(`list_sections.${i}.noq_attempted`, noqAttempted);
      }
      if (noqTotal) {
        setFieldValue(`list_sections.${i}.noq_total`, noqTotal);
      }

      for (let j = 0; j < questionsList.list_sections[i].list_qgs.length; j++) {
        for (
          let k = 0;
          k < questionsList.list_sections[i].list_qgs[j].list_ques.length;
          k++
        ) {
          const qno =
            questionsList.list_sections[i].list_qgs[j].list_ques[k].qno;
          const dictQnoAttempted =
            questionsList.list_sections[i].dict_qno_attempted;
          const listQues =
            questionsList.list_sections[i].list_qgs[j].list_ques[k];

          setFieldValue(`list_sections.${i}.dict_qno_attempted.${qno}`, false);

          // for questions after save as draft and submit
          if (
            (dictQnoAttempted && dictQnoAttempted[qno]) ||
            (has(listQues, "attempted_obj") && !isNull(listQues.attempted_obj))
          ) {
            setFieldValue(`list_sections.${i}.dict_qno_attempted.${qno}`, true);
          }

          setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.qno`,
            qno
          );
          setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
            null
          );
          setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.list_times`,
            []
          );
          setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.list_starts`,
            []
          );

          // for submitted Question
          if (has(listQues, "attempted_obj")) {
            setFieldValue(
              `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
              listQues.attempted_obj
            );
          }
          // for Questions after save as a draft
          if (
            questionsList &&
            questionsList.draft &&
            questionsList.draft.attempted
          ) {
            setFieldValue(
              `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
              listQues.draft.stud_ans
            );
          }
          setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.q_type`,
            listQues.q_type
          );
          setFieldValue(
            `list_sections.${i}.list_ags.${j}.list_ans.${k}.q_id`,
            listQues.q_id
          );
        }
      }
    }
    setLoadingForResponse(false);
    return () => {
      localStorage.removeItem("time");
    };
  }, [questionsList]);

  const onEnd = () => {
    setId(null);
  };
  const { speak } = useSpeechSynthesis({ onEnd });

  const handleClick = (data, questionId) => {
    if (isEmpty(questionsList)) return;
    const { q_type: qType } = data;
    const { i, j, k } = getQuestionIndex(questionsList, data);
    questionIndex.current = { i, j, k };
    const props = {};
    const changeClass = () => {
      speak({ text: data.q_html });
      setId(k);
    };
    const active = () => {
      if (id === k) {
        return true;
      }
      return false;
    };

    props.key = k;
    props.active = active();
    props.onPress = changeClass;
    props.data = data;
    props.indexValue = { i, j, k };
    props.value = values;
    props.onChange = handleChange;
    props.setFieldVal = setFieldValue;
    props.isTimedQuestion = true;
    props.questionId = questionId + 1;
    props.fieldValue = getFieldVal(values, i, j, k);
    props.name = `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`;

    if (isEmpty(values)) return;

    if (shouldSaveDataAsDraft.current) {
      shouldSaveDataAsDraft.current = false;
      dispatch(
        isAdmin ? adminTimedSaveAsDraft(values) : timedSaveAsDraft(values)
      );
    }

    switch (qType) {
      case "TextAnswerQ":
        return <TextAnswerQ {...props} />;
      case "DictationQ":
        return <DictationQ {...props} />;
      case "MathExpressionQ":
        return <MathExpressionQ {...props} />;
      case "MultipleChoiceQ":
        return <MultipleChoiceQ {...props} />;
      case "UniqueChoiceQ":
        return <UniqueChoiceQ {...props} />;
      case "TrueFalseQ":
        return <TrueFalseQ {...props} />;
      case "FillImgQ":
        return <FillImgQ {...props} />;
      case "FillBlankQ":
        return <FillBlankQ {...props} />;
      case "FillGroupQ":
        return <FillGroupQ {...props} />;
      case "FillInlineQ":
        return <FillInlineQ {...props} />;
      case "MatchItemsQ":
        return <MatchItemsQ {...props} />;
      case "MatrixMatchQ":
        return <MatrixMatchQ {...props} />;
      default:
        return "";
    }
  };

  const handleAttempted = (e) => {
    if (e && e.ans_obj && e.ans_obj.correctness === "right") {
      return "right";
    } else if (e && e.ans_obj && e.ans_obj.correctness === "wrong") {
      return "wrong";
    } else if (
      e &&
      e.attempted_obj &&
      e.ans_obj &&
      isArray(e.ans_obj.ugot_right)
    ) {
      if (
        e &&
        e.ans_obj &&
        e.ans_obj.ugot_right &&
        e.ans_obj.ugot_right.every((e) => e === true)
      ) {
        return "right";
      } else {
        return "wrong";
      }
    } else if (
      !isEmpty(values) &&
      values.list_sections[getQuestionNumber(values, e)].dict_qno_attempted[
        e.qno
      ] === true
    ) {
      return "question_attempted";
    } else {
      return "";
    }
  };

  const handleMarkForReview = (e, id) => {
    const tempArray = [...markForReviewQuestions];
    tempArray[id] = { id, checked: e.target.checked };
    setMarkForReviewQuestions(tempArray);
  };

  const handleSubmittedTimedSection = () => {
    navigate(isAdmin ? "/admin/practice" : "/practice");
  };

  const getMarkForReviewQuestions = (markForReviewQuestions) => {
    const tempArray = markForReviewQuestions
      .filter((ele) => ele.checked)
      .map((el) => el.id + 1);
    if (tempArray.length === 1) {
      return `${tempArray[0].toString()} is`;
    } else {
      const formattedArray =
        tempArray.slice(0, -1)?.join(", ") +
        (tempArray.length > 2 ? "," : "") +
        ` and ${tempArray[tempArray.length - 1]} are`;
      return formattedArray;
    }
  };

  useEffect(() => {
    const ansField = values?.list_sections?.map((list) =>
      list.list_ags?.[0]?.list_ans?.find(
        (item) => item.q_id === currentQuestion.q_id
      )
    );

    dispatch(
      storeCurrentQuestionAns({
        ans_key:
          ansField?.[0]?.stud_ans ??
          ansField?.[1]?.stud_ans ??
          ansField?.[2]?.stud_ans,
      })
    );
  }, [values, currentQuestion]);

  const modalContentArray = [
    "This set was already submitted for grading.",
    "It cannot be re-taken.",
  ];
  const modalContent = modalContentArray.map((e, i) => <p key={i}>{e}</p>);
  const modalFooterButtons = [
    {
      label: "Ok",
      variant: "danger",
      onClick: handleSubmittedTimedSection,
    },
  ];

  const footerButtons = [
    {
      label: "Cancel",
      variant: "danger",
      onClick: () => setShow(false),
    },
    {
      label: isSubmit ? "Submit" : "Exit",
      variant: "primary",
      onClick: handleSubmit,
    },
  ];

  if (loading) {
    return <div className="loader_section">Loading...</div>;
  }

  if (submittedSectionModal) {
    return (
      <CustomModal
        content={modalContent}
        footerButtons={modalFooterButtons}
        show={submittedSectionModal}
        handleClose={handleSubmittedTimedSection}
      />
    );
  }

  if (loadingForResponse) {
    return <div className="loader_section">Loading...</div>;
  }

  if (questionsList && questionsList.error) {
    return <ErrorHandleComponent errorMessage={questionsList.message} />;
  }

  if (isEmpty(qList)) return;

  return (
    <div className="timed_section">
      {timedSectionLoading && (
        <div className="timed_section_loader">
          <Loader />
        </div>
      )}
      <div className="questions_wrapper">
        <div className="question_section_wrapper">
          <section className="timer_section">
            <Container>
              <div className="timer_wrapper">
                <div className="timer_left_buttons">
                  <Button
                    onClick={() => {
                      setShow(true);
                      setIsSubmit(false);
                    }}
                    className="exit_btn"
                  >
                    <ReactSVG src={exitIcon} className="arrow_icon" />
                  </Button>
                  <Button
                    onClick={() => {
                      setWarningShow(true);
                    }}
                    className="warning_btn"
                  >
                    <ReactSVG src={warningIcon} className="warning_icon" />
                  </Button>
                </div>
                <div className="time_center_infoo d-flex gap-2 align-items-center">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip className="cs-tooltip">
                        <div>
                          <p className="mb-1">{setStore?.type}</p>
                          <strong>{setStore?.setname} </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div className="pe-lg-3">
                      <img src={infoIcon} width={24} />
                    </div>
                  </OverlayTrigger>
                  {closewarntimer === false ? (
                    <Form.Group className="mb-0">
                      <Form.Check
                        type="checkbox"
                        label="Show time remaining"
                        checked={closetimer}
                        onChange={() => setClosetimer(!closetimer)}
                      />
                    </Form.Group>
                  ) : null}
                  <div>
                    {!closetimer ? (
                      <div className="timmer_section_div"></div>
                    ) : (
                      <div className="timmer_section_div">
                        <Timer
                          handleSubmit={() => {
                            setIsSubmit(true);
                            handleSubmit(onSubmit);
                          }}
                          setClosetimer={setClosetimer}
                          setClosewarntimer={setClosewarntimer}
                          setSubmitMode={setSubmitMode}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  onClick={() => {
                    setIsSubmit(true);
                    setShow(true);
                  }}
                  className="grad_submit_btn"
                >
                  Submit for grading
                </Button>
              </div>
            </Container>
          </section>
          <section className="question_section">
            <Container>
              <div className="questions_wrap">
                <Container id={questionId + 1}>
                  {handleClick(qList[questionId], questionId)}
                  <Form.Check
                    inline={true}
                    className="mark_for_review_checkbox"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      id="mark_for_review"
                      name="mark_for_review"
                      onChange={(e) => handleMarkForReview(e, questionId)}
                      value={markForReviewQuestions?.[questionId]?.checked}
                      checked={markForReviewQuestions?.[questionId]?.checked}
                    />
                    <Form.Check.Label htmlFor="mark_for_review">
                      Mark for review
                    </Form.Check.Label>
                  </Form.Check>
                </Container>
              </div>
              <FlaggedQuestionModal flagQuestion={flagQuestionModal} />
            </Container>
          </section>
        </div>
        <div className="questions_list_box">
          <Container>
            <div className="question_list_section">
              <div className="question_list_section_wrapper">
                {qList.map((e, i) => {
                  return (
                    <div
                      className={`dots ${handleAttempted(e)} ${
                        i === questionId ? "current_que" : ""
                      } ${
                        isArray(markForReviewQuestions) &&
                        markForReviewQuestions?.filter(
                          (ele) => ele.id === i && ele.checked
                        ).length > 0
                          ? "mark_for_review"
                          : ""
                      }`}
                      key={i}
                      onClick={() => {
                        setStore?.practiceType === "weekly"
                          ? (setQuestionId(i), onSaveAsDraft())
                          : "";
                      }}
                    >
                      {i + 1}
                    </div>
                  );
                })}
              </div>

              <Button
                disabled={questionId === 0}
                onClick={() => {
                  setQuestionId(questionId - 1);
                  startTime.current = Date.now();
                }}
                className="arrow_btn prev"
              >
                <ReactSVG src={prevIcon} className="arrow_icon" /> Prev
              </Button>

              <Button
                onClick={() => {
                  setQuestionId(questionId + 1);
                  onSaveAsDraft();
                }}
                className="arrow_btn next next_btn"
                disabled={questionId === qList.length - 1}
              >
                Next <ReactSVG src={nextIcon} className="arrow_icon" />
              </Button>
            </div>
          </Container>
        </div>
        <CustomModal
          title={
            isSubmit
              ? "Are you sure you want to submit?"
              : "Are you sure you want to exit?"
          }
          content={
            <>
              <p>You wont be able to attempt this question set again.</p>
              {isArray(markForReviewQuestions) &&
                markForReviewQuestions.filter((ele) => ele.checked).length >
                  0 && (
                  <p>
                    <strong>
                      {`Question no. 
										${getMarkForReviewQuestions(markForReviewQuestions)} pending for review`}
                    </strong>
                  </p>
                )}
            </>
          }
          footerButtons={footerButtons}
          show={show}
          handleClose={() => setShow(false)}
        />
        <CustomModal
          title="Warning"
          content={
            <p>
              Do not press the back button, refresh the page, or close the
              window during the exam. Doing so will automatically submit the
              exam, and you will not be able to resubmit it. Please ensure that
              you complete the exam without performing any of these actions to
              avoid unintended submission.
            </p>
          }
          show={warningShow}
          handleClose={() => setWarningShow(false)}
        />
      </div>
    </div>
  );
};

export default TimedSectionPage;
