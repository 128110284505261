/* eslint-disable */
import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'

const TermsPage = () => {
    return (
		<div className='tos-page'>
            <section class="inner_banner_section">
                <div class="container">
                    <h1>Terms of Use</h1>
                    <div>(Updated on: May 28, 2023)</div>
                </div>
            </section>
            <div class='tos-page-content'>
                <Container>
                    <p>These Terms of Use ("Terms") for the use of our website <a href="https://boo9.com/" target="_blank">https://boo9.com/</a>
                        ("Website"), our applications ("Application") or any products or services in connection with the
                        Application/Website/products ("Services") are between boo9 Inc. ("Company", "We", "Us" or "Our") and its users
                        ("User", "You", or "Your"). For the sake of clarity, "User" herein refers to anyone visiting our platform,
                        application or Website or anyone who accesses or uses our platform, application or Website.</p>

                    <p>Please read the Terms and the privacy policy of the Company ("Privacy Policy") with respect to registration or
                        subscription with us, the use of the Application, Website, Services and products, carefully before using the
                        Application, Website, Services or products. In the event of any discrepancy between the Terms and any other policies
                        with respect to the Application or Website or Services or products, the provisions of the Terms shall prevail.</p>

                    <p>Your use/access/browsing of the Application or Website or the Services or products or registration (with or without
                        payment/with or without subscription) through any means shall signify Your acceptance of the Terms and Your
                        agreement to be legally bound by the same. If you do not agree with the Terms or the Privacy Policy, please do not
                        use the Application or Website or avail the Services or products. Any access to our Services/Application/products
                        through registrations/subscription is non transferable.</p>

                    <p>We have the right to change the provisions contained in these Terms of Use as and when considered necessary by
                        us, without any notice to you. If we make any such revision in the Terms, we will update the effective date above
                        and the revised Terms shall be effective from such date. You must frequently check these Terms and its effective
                        date to understand the conditions that apply to your use of our Website. Your continued use of the Website following
                        such modification constitutes your acceptance of the modified Terms. If you disagree with any of these terms, you
                        are prohibited from using or accessing this Website.</p>

                        <p className="summary-table">
                            <b>In simple terms:</b> This tells you that if you create an account with us and use our service you agree to the conditions that come with it.
                        </p>


                    <ol type="1">
                        <li class="header">Definitions</li>
                        <ol type="1">
                            <li>
                                <b>"Agreement"</b> is the collective term that encompasses these Terms, and/or any Service Plan, and if applicable,
                                Content Subscription.
                            </li>
                            <li>
                                <b>"Children"</b> means children in the U.S. under the age of 13 years and children outside the U.S. under the age
                                of 16 years.
                            </li>
                            <li><b>"Content Subscriptions"</b> means the various libraries of premium content available for purchase on a
                                subscription basis in addition to the Service Plan chosen by each user.
                            </li>
                            <li><b>"Licence"</b> means the license granted to you pursuant to these Terms for the usage of the Website and the
                                content available.
                            </li>
                            <li><b>"Platform"</b> means boo9's Websites and Apps where the boo9 Services are available.</li>
                            <li><b>"Terms"</b> means these general terms and conditions of the use applicable to your use of the boo9 Services.
                            </li>
                            <li><b>"Third Party Applications"</b> means websites and services delivered by third parties that are integrated in the
                                Services and resources in order to make certain features, content, products, and/or services available to
                                you.
                            </li>
                            <li><b>"User Content"</b> means the content and data which Users upload to the Service, including without limitation
                                pictures, text, messages, information, user feedback, and any other content.
                            </li>
                            <li><b>"Website"</b> means the boo9 website, <a href="https://boo9.com/" target="_blank">https://boo9.com/</a></li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> This is how we define the meaning of some words that we use in this document.
                        </p>


                        <li class="header">Our Services and your Terms of Use</li>
                        <ol>
                            <li>We are an edtech platform which is an endeavor to make learning more rewarding and fulfilling and a less
                                burdensome experience, helping the students learn and grow ("Services").
                            </li>
                            <li>We operate as a platform which helps you to practice educational content, learn concepts, explore
                                interactive options of learning and evaluate yourself from time to time.
                            </li>
                            <li>The Website conducts its business through 3 (three) models and information is collected as per your usage
                                and our "need" pertaining to the said 3 (three) models.
                            </li>
                            <li>Our models are:
                                <ol type="i">
                                    <li><b>Model 1:</b> Free to use model wherein no account creation is required. Access is provided to you free
                                        of cost for a limited material.
                                    </li>
                                    <li><b>Model 2:</b> Free to use model wherein an account must be created by you for further access to certain
                                        additional material, but no credit card information is required.
                                    </li>
                                    <li><b>Model 3:</b> Premium model wherein you're supposed to pay through credit card or a cash payment
                                        application to avail premium services.
                                    </li>
                                </ol>
                            </li>
                            <li>We also offer interactive learning wherein the Users get to try things "hands-on", learn with step-by-step
                                explanations, or explore various scientific phenomena.
                            </li>
                            <li>When registering with boo9 you must provide accurate, complete, and current registration information and you
                                agree to provide us with any updates to that information promptly after such changes occur.
                            </li>
                            <li>We verify the email addresses and phone numbers provided for the Users of the Basic account through one time
                                password (the "OTP") and for the Users of Premium account, additionally, credit card details are required.
                            </li>
                            <li>The Users in both, the Basic account and for Premium account, are required to declare themselves to be more
                                than 16 years old or the guardians of children below 16 years, consenting to their children to access and
                                use the Services provided by us. This provision is in compliance with the requirement of parental consent.
                            </li>
                            <li>You shall be responsible for obtaining the computer/mobile equipment and other devices necessary to access
                                and use the site. You shall be responsible for all charges associated with accessing and maintaining a
                                connection to the site including, but not limited to, charges imposed by an internet service provider.
                            </li>
                            <li>To use our Services, pursuant to Basic and Premium model, you need to create an account with us, by
                                registering and creating a User ID.
                            </li>
                            <li> When you use the Website, we assume that you are competent to form legally binding contracts under
                                applicable local laws of your native country. Anyone acting on behalf of a legal person (i.e. entity) must
                                have sufficient authority to bind the said legal person to these terms (in which case the term "you"
                                throughout these Terms of Use will refer to such entity). We reserve the right to terminate your account
                                and / or deny access to our Services if it is brought to our notice or if it is discovered that you do not
                                meet this condition.
                            </li>
                            <li>You will be responsible for maintaining confidentiality of your account, password, and keep its access
                                restricted, and you hereby accept responsibility for all activities that occur under your account. If you
                                know or have reasons to believe that the security of your account has been breached, you should contact us
                                immediately at the contact details provided on our Website. If we have found a breach or suspected breach of
                                the security of your account, we may require you to change your password, temporarily or permanently block
                                or suspend your account without any liability to us.
                            </li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> These are conditions according to which you use our services.
                        </p>

                        <li><b>Users Representations:</b> By using the Website or our Services, you represent that:</li>
                        <ol>
                            <li>Your use of this website shall not breach any national or international laws and shall not breach any legal
                                contract, policy or obligation you are currently a party to or in a manner that infringes the intellectual
                                property rights of any third party.
                            </li>
                            <li>You will not copy, or make use of any part of the Website for any purpose whatsoever unless expressly
                                permitted to do so in these terms.
                            </li>
                            <li>You will not access the site through automated or non-human means, whether through a bot, script or
                                otherwise.
                            </li>
                            <li>You will not knowingly or unknowingly use the Website or any of the Services in a way that abuses or
                                disrupts our networks or any other service boo9 provides.
                            </li>
                            <li>boo9, at all times and at its sole discretion, reserves the right to disable, suspend or terminate your
                                account if you fail to comply with any of the provisions of these Terms of Use. We shall have all the
                                rights to take necessary action and claim damages that may occur due to your involvement/participation in
                                any activity which causes any harm or disrepute to boo9 in any way either on your own or through group/s of
                                people, intentionally or unintentionally.
                            </li>
                        </ol>
                        <p className="summary-table"><b>In simple terms:</b> This is how you will access our website or app.</p>

                        <li class="header">User License</li>
                        <ol>
                            <li>boo9 grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make use of
                                the Services or any other materials on the Website only to the extent provided by these Terms of Use.
                                All rights not expressly granted to you in these Terms of Use are reserved and retained by us.
                            </li>
                            <li>Permission is granted to browse through the practice and study materials (information or software) and to
                                download the practice sets on our Website for personal, non-commercial use. This is the grant of a license,
                                not a transfer of title, and under this license you may not:
                            </li>

                            <ol>
                                <li>modify or copy the materials;</li>
                                <li> the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                <li>attempt to decompile or reverse engineer any software contained on the Website;</li>
                                <li>remove any copyright or other proprietary notations from the materials; or</li>
                                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                            </ol>
                            <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by
                                boo9 at any time. Upon terminating your viewing of any of the materials or upon the termination of this license,
                                you must destroy any downloaded materials in your possession whether in electronic or printed format.
                            </li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> This is what you are permitted to do and what you are not.</p>

                        <li class="header">Website Availability</li>
                        <ol>
                            <li>While we endeavor to make the Website available 24X7, we do not represent that its access will be
                                uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be
                                corrected.
                            </li>
                            <li>We do not warrant that the Website or the Services available on our Website will be compatible with all
                                hardware and software which may be used by you to avail our Services.
                            </li>
                            <li>The Website may undergo constant upgrades, as a result of which some functions and features may not be fully
                                operational. We provide the Website on an 'as is' and 'as available' basis and expressly disclaim all
                                warranties of any kind, whether express or implied, with respect to the Services and other products or
                                materials, data made available by it to you.
                            </li>
                            <li>We reserve the right to make changes to any part of the Website including but not limited to appearance,
                                function, likeness, offerings, layout at our discretion. However, we have no obligation to update any
                                information on our Website. We also reserve the right to modify or discontinue all or part of the Services
                                without notice at any time. We will not be liable to you or any third party for any modification, price
                                change, suspension, or discontinuance of the Services.
                            </li>
                            <li>You shall be solely responsible for any damage to your data system or for loss of data arising from download
                                of any form of content from the Website. No guidance or information, written or oral, obtained from us,
                                shall constitute any warranty, unless stated otherwise.
                            </li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> We want to give you uninterrupted service but sometimes it is necessary to update it and become
                            unavailable for your benefit.
                        </p>


                        <li class="header">Charges and Refunds</li>
                        <ol>
                            <li>All pricing, payment and refunds are at the discretion of the Company.</li>
                            <li>In order to pay for the premium subscription through the Website, you may be asked to provide your credit
                                card or cash payment application details. By providing such card details, agreeing to allow us to save such
                                card information and billing address for that card, you represent that you are the credit/debit card holder
                                or that you are an authorized representative of the credit/debit card holder.
                            </li>
                            <li>Credit card fraud is a felony. We may report any wrongful or fraudulent chargebacks to your local police,
                                your local bank, our merchant service (credit card processing company). We will aggressively prosecute
                                anyone attempting to misuse a credit card number. We track every transaction and will use all means
                                available to prosecute persons attempting fraud.
                            </li>
                            <li>You agree to bear all bank charges in relation to the payment made and agree to pay all applicable taxes
                                levied on the services utilized by you in connection with using the website.
                            </li>
                            <li>To threaten a chargeback to alter purchase price after an order is placed is extortion, and a criminal
                                offense which we can rightfully prosecute to the full extent of the law.
                            </li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> This is how we go about our payment process.
                        </p>

                        <li class="header">Intellectual Property</li>
                        <ol>
                            <li>The intellectual property contained in the Website is owned by or licensed to boo9 and is protected by
                                applicable copyright and trademark law.
                            </li>
                            <li>Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any
                                license or transfer of right to any copyright, patent, trademark or other proprietary interest belonging to
                                boo9. The content provided in this site, including, but not limited to, graphic images, audio, video, html
                                code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or
                                distributed in any way, without the prior written consent of boo9.
                            </li>
                            <li>You grant the Company the right to use your name, photograph and any work you produce as part of practice
                                sets or coursework in a reasonable and truthful manner.
                            </li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> This is what we own and have complete ownership of what we created.
                        </p>

                        <li class="header">Term and Termination</li>
                        <ol>
                            <li>The Agreement will continue to apply for as long as you use a free or paid boo9 account. boo9 may terminate
                                the Agreement or suspend your access to the boo9 Service at any time, including in the event of your actual
                                or suspected unauthorized use of the boo9 Service and/or Resources, or non-compliance with the Agreement. If
                                you or boo9 terminate the Agreement, or if boo9 suspends your access to the boo9 Service in accordance with
                                the Agreement, you agree that boo9 shall have no liability or responsibility to you and boo9 will not refund
                                any amounts that you have already paid, to the fullest extent permitted under applicable law.
                            </li>
                            <li>You hereby acknowledge and agree that the perpetual license granted to boo9 by you will continue after expiry
                                or termination of any of the Agreement for any reason.
                            </li>
                            <li>Sections of the Agreement that, either explicitly or by their nature, must remain in effect even after termination
                                of the Agreement, shall survive termination.
                            </li>
                        </ol>
                        <p className="summary-table">
                            <b>In simple terms:</b> You may choose to discontinue being with us by following this process.
                        </p>

                        <li class="header">Warranty and Disclaimer</li>
                        <ol>
                            <li>boo9 shall at its own expense (i) use reasonable endeavors to rectify any non-compliance, and if rectification
                                is not reasonably possible (ii) replace all the non-conforming parts of the content. boo9 disclaims any
                                other warranties than those set out in the Agreement. Further, under no circumstances will boo9 be liable
                                for any Third Party Applications, including the failure of any such Third Party Applications.
                            </li>
                            <li>boo9 shall not be responsible for breach of these warranties if caused by:</li>
                            <ul>
                                <li>hardware, software or other components which are not part of the Services;</li>
                                <li>errors or problems caused by or contributed to by hardware, software or other components which are not
                                    provided by boo9, including any modifications by you or third parties;
                                </li>
                                <li>errors or problems caused by you not complying with this Agreement;</li>
                                <li>if you have rejected the implementation of any upgrade, change, hot fix, or similar, which would have
                                    prevented the error or problem; and
                                </li>
                                <li>errors or problems caused by third parties not acting on behalf of boo9, including in respect of changes to
                                    Services.
                                </li>
                            </ul>
                        </ol>
                        <p className="summary-table"> <b>In simple terms:</b> This is what we cover in our warranty.</p>

                        <li class="header">Limitation of liability</li>
                        <ol>
                            <li>To the maximum extent permitted by law, in no event will boo9, its officers, shareholders, employees,
                                agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors be liable for (1)
                                any indirect, special, incidental, punitive, exemplary, or consequential damages; or (2) any loss of use,
                                data, business, or profits (whether direct or indirect), in all cases arising out of the use or inability to
                                use the boo9 service, third party applications, or third party application content, regardless of legal
                                theory, without regard to whether boo9 has been made aware of the possibility of those damages, and even if
                                a remedy fails of its essential purpose. boo9's aggregate liability for all claims arising under or in
                                connection with this agreement shall be limited to the amounts paid by you to boo9 under this agreement
                                during the twelve months immediately preceding the last event giving rise to liability.
                            </li>
                            <li>Nothing in the agreement removes or limits boo9's liability for fraud, fraudulent misrepresentation, and, if
                                required by applicable law, gross negligence.
                            </li>
                        </ol>
                        <p className="summary-table"><b>In simple terms:</b> This is how we limit our liability.</p>

                        <li class="header">Indemnification</li>
                        To the fullest extent permitted by applicable law, you agree to indemnify and hold harmless boo9 and its parent
                        company and affiliates, and their directors, officers, managers, employees, donors, agents, and licensors, from and
                        against any claims and all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting
                        from: (1) Your breach of the Agreement; (2) any Content posted or provided by you during the term of your usage of
                        the Website; (3) any activity in that You or anyone using your account engages on or through the boo9 Service; and
                        (4) Your violation of any law or the rights of a third party. We reserve the right to take over the exclusive
                        defense of any claim for which we are entitled to indemnification under these Terms.
                        In such an event, you shall provide us with such cooperation as is reasonably requested by us.

                        <p className="summary-table"><b>In simple terms: </b> This is how we want you and us to be harmless towards each other.
                        </p>


                        <li class="header">Third Party Links</li>
                            We may provide links to third-party websites, and some of the content appearing to be on the Website may in-fact
                            be supplied by third parties. We do not endorse and have no responsibility for the availability or content of
                            these third-party websites, which are governed by the terms of use and privacy policies, if any, of the
                            applicable third-party content providers.
                            We shall have no liability or responsibility, directly or indirectly, for any damage or loss caused or alleged
                            to be caused by the use or reliance on any content, goods or services available on or through such websites.
                            <p className="summary-table"><b>In simple terms:</b> This is what we can tell you about the third parties that may or
                                may not work with us sometimes.
                            </p>

                        <li class="header">Privacy</li>
                        Your privacy is very important to us and any data received by us shall be handled in accordance with our <a href="https://boo9.com/privacy-policy" target="_blank">Privacy Policy</a>.

                        <li class="header">Governing Law and Dispute Resolution</li>
                        <ol>
                            <li>These Terms of Use shall be construed in accordance with the applicable laws of the State of California
                                without regard to the conflict of law principles.
                            </li>
                            <li>If any dispute or claim arises from or in connection with (i) these Terms of Use, (ii) any premium
                                subscription taken through the Website, or (iii) your access to or use of our Services ("Dispute"), the
                                relevant parties shall resolve the Dispute through mutual negotiation.
                            </li>
                            <li>In the first instance any Dispute that you have with regards to the use of the Website will be referred to
                                the senior management of boo9. If such Dispute is not resolved within a period of 30 (thirty) days from the
                                date on which it was referred to the senior management or such extended period as the senior management may
                                agree with you, you agree to submit to the exclusive jurisdiction of the courts at California for the
                                resolution of the Dispute.
                            </li>
                        </ol>

                        <li class="header">Miscellaneous</li>
                        <ol>
                            <li>Notice: All notices served by the Company shall be provided via email to Your account or as a general
                                notification on the Application. Any notice to be provided to the Company should be sent to <a href="mailto:boo9info@gmail.com">boo9info@gmail.com</a>.
                            </li>
                            <li>Entire Agreement: The Terms, along with the Privacy Policy, and any other guidelines made applicable to the
                                Application from time to time, constitute the entire agreement between the Company and You with respect to
                                Your access to or use of the Application, Website and the Services thereof.
                            </li>
                            <li>Assignment: You cannot assign or otherwise transfer Your obligations under the Terms, or any right granted
                                hereunder to any third party. The Company's rights under the Terms are freely transferable by the Company to
                                any third parties without the requirement of seeking Your consent.
                            </li>
                            <li>Severability: If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or
                                portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so
                                as to give effect to the intent of the parties as reflected by that provision, and the remainder of the
                                Terms shall continue in full force and effect.
                            </li>
                            <li>Waiver: Any failure by the Company to enforce or exercise any provision of the Terms, or any related right,
                                shall not constitute a waiver by the Company of that provision or right.
                            </li>
                        </ol>
                        <p className="summary-table"><b>In simple terms:</b> This is what boilerplate clauses look like in a contract.</p>

                        <li class="header">Changes to these Terms</li>
                        We reserve the right to make changes to this Website Terms, such as to reflect changes in the law or to our Website.
                        These Terms, including any changes to these terms, will be posted on our Website. You are responsible for regularly
                        reviewing our Website and these Website Terms to obtain notice of such modifications. Your continued use of our
                        Website following an update to the Website Terms will constitute your acceptance of the updated Website Terms.
                        <p className="summary-table">
                            <b>In simple terms:</b> We tell you what changes we make to this document.
                        </p>


                        <li class="header">Contact Us</li>
                        If you have any queries, complaints, or concerns with regards to content and or breach of these terms, please
                        immediately reach out to us at our email address, <a href="mailto:boo9info@gmail.com">boo9info@gmail.com</a>

                        <p className="summary-table"><b>In simple terms:</b> This is where you may tell us how annoyed or appreciative you are of
                            our efforts to make you feel secure and safe about using our service.</p>
                    </ol>
                </Container>
            </div>
		</div>
	);
};

export default TermsPage;
