/* eslint-disable max-len */
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, NavLink } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Header from '../../components/HeaderComponent';
import Footer from '../../components/FooterComponent';
import BannerBottom from '../../assets/images/banner-bottom.svg';
// import Slide1Gray from '../../assets/images/icons/landing_MathCompetitionsAllInOnePlace.svg';
// import Slide2Gray from '../../assets/images/icons/landing_ConvenientSaveTimeFree.svg';
import intelaExplore from '../../assets/images/intela_explore.png';
import intelaHandson from '../../assets/images/intela_handson.jpeg';
import intelaStepbystep from '../../assets/images/intela_stepbystep.jpeg';
import Muscle from '../../assets/images/muscle.png';
import Fun from '../../assets/images/have_fun.png';
import ExpandArrows from '../../assets/images/expand-arrows.png';
import Globe from '../../assets/images/globe.png';
// --
import SlideMK from '../../assets/images/landing_slide__mk.png';
import SlideNEETAndJEE from '../../assets/images/landing_slide__neet_and_jee.png';
// import SlidePractice from '../../assets/images/landing_slide__practice.png';
import SlideFacilities from '../../assets/images/landing_slide__facilities.png';
import SlideTesti from '../../assets/images/landing_slide__testimonial.png';
import Slider from 'react-slick';
import reviewData from '../../utils/testimonials.json';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';
// import YoutubeEmbed from './YoutubeEmbed';

const HomePage = () => {
	const { isLoggedIn } = useSelector((state) => state.auth);
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();

	const practiceNavText = [
		'Math Kangaroo',
		'NEET & JEE',
		'Practice facilities',
		'A testimonial',
		'Thank you!'];
	const interactiveSliderText = [
		'Try things hands-on',
		'Explore how things work',
		'Understand step by step'];

	const PracticeSlider = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000,
		customPaging: (i) => {
			return (
				<div>{practiceNavText[i]}</div>
			);
		}
	};
	const InteractiveSlider = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 4000
	};

	const InteractiveDotsSlider = {
		dots: true,
		arrow: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 4000,
		customPaging: (i) => {
			return (
				<div>{interactiveSliderText[i]}</div>
			);
		}
	};

	const TestimonialSlider = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 4000
	};
	// const navigate = useNavigate()
	return (
		<div className='home_page'>
			<Header theme='light' />
			<div className='home_conatiner'>
				<section className='banner_section'>
					<div className='banner_wrapper'>
						<Slider className="home-slider" {...PracticeSlider}>
							<div className="slide_item">
								<div className='slide_content'>
									<img src={SlideMK} />
								</div>
							</div>
							<div className="slide_item">
								<div className='slide_content'>
									<img src={SlideNEETAndJEE} />
								</div>
							</div>
							<div className="slide_item">
								<div className='slide_content'>
									<img src={SlideFacilities} />
								</div>
							</div>
							<div className="slide_item">
								<div className='slide_content'>
									<img src={SlideTesti} />
								</div>
							</div>
							<div className="slide_item">
								<div className='slide_content'>
									<div className="slide_content_text">
										<h2>Thank you for your support! We won’t let you down.</h2>
									</div>
								</div>
							</div>
							{/* <div className="slide_item">
								<div className='slide_content'>
									<img src={SlideHungry} />
								</div>
							</div> */}
							{/* <div className="slide_item">
								<div className='slide_content'>
									<div className="slide_content_text">
										<h2>Hungry to learn? We are eager to teach!</h2>
										<p>Math . Physics . Problem Solving</p>
									</div>
								</div>
							</div> */}
							{/* <div className="slide_item">
								<div className='slide_content'>
									<div className="slide_content_text">
										<h2>Strive to achieve; we are here to help.</h2>
										<p>Physics for NEET & JEE . Practice PYQs . Coming soon ...</p>
									</div>
								</div>
							</div> */}
							{/* <div className="slide_item">
								<div className='slide_content'>
									<div className="svg_item" style={{ maxWidth: 600 }}>
										<ReactSVG src={Slide1Gray} className='slider_svg' />
									</div>
								</div>
							</div> */}
							{/* <div className="slide_item">
								<div className='slide_content'>
									<div className="svg_item" style={{ maxWidth: 600 }}>
										<ReactSVG src={Slide2Gray} className='slider_svg' />
									</div>
								</div>
							</div> */}
						</Slider>
						<Link className="btn btn-primary video-btn" to="/#video-section">
							<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
								<path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
							</svg>
							Videos
						</Link>
						<Link className="btn btn-primary practice-btn" to="practice">Practice</Link>
					</div>
					<div className="down-arrow">
						<span></span>
						<span></span>
						<span></span>
					</div>
					<ReactSVG src={BannerBottom} className='banner_bottom' />
				</section>
				<section className="services_section">
					<Container>
						<div className='services_wrapper'>
							{/* <h3>Our Services</h3> */}
							<h2>Our approach to learning</h2>
							{/* <p>Practice and learn, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
							<div className="services_list">
								<div className="service_box">
									<div className='service_box_inner'>
										<div className='service_icon'>
											<img src={Muscle} />
										</div>
										<strong>Concept</strong>
									</div>
								</div>
								<div className="service_box">
									<div className='service_box_inner'>
										<div className='service_icon'>
											<img src={ExpandArrows} />
										</div>
										<strong>Technique</strong>
									</div>
								</div>
								<div className="service_box">
									<div className='service_box_inner'>
										<div className='service_icon'>
											<img src={Globe} />
										</div>
										<strong>Practice</strong>
									</div>
								</div>
								<div className="service_box">
									<div className='service_box_inner'>
										<div className='service_icon'>
											<img src={Fun} />
										</div>
										<strong>Have fun</strong>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</section>

				<section className="video-section" id="video-section">
					<Container>
						<div className='video-section_wrapper'>
							<h2>Our latest videos</h2>
							<div className='video-container'>
								<iframe
									src="https://www.youtube.com/embed?list=UULFe9U_0An5beIExnkyRI6TlQ">
								</iframe>
								<iframe
									src="https://www.youtube.com/embed?list=UULFe9U_0An5beIExnkyRI6TlQ&index=2">
								</iframe>
								<iframe
									src="https://www.youtube.com/embed?list=UULFe9U_0An5beIExnkyRI6TlQ&index=3">
								</iframe>
							</div>
						</div>
					</Container>
				</section>
				<section className="video-section" id="video-section">
					<Container>
						<div className='video-section_wrapper'>
							<h2>Our popular videos</h2>
							<div className='video-container'>
								<iframe
									src="https://www.youtube.com/embed?list=UULPe9U_0An5beIExnkyRI6TlQ">
								</iframe>
								<iframe
									src="https://www.youtube.com/embed?list=UULPe9U_0An5beIExnkyRI6TlQ&index=2">
								</iframe>
								<iframe
									src="https://www.youtube.com/embed?list=UULPe9U_0An5beIExnkyRI6TlQ&index=3">
								</iframe>
							</div>
						</div>
					</Container>
				</section>

				<section className="interactive_learning">
					<Container>
						<div className='interactive_learning_wrapper'>
							<div className='interactive_learning_content'>
								<h2>Interactive learning … <br />coming soon!</h2>
								<div className="slider_section">
									<Slider {...InteractiveDotsSlider} asNavFor={nav1} ref={(slider2) => setNav2(slider2)}>
										<div className="slide_item"></div>
										<div className="slide_item"></div>
										<div className="slide_item"></div>
									</Slider>
								</div>
								<Link className="btn" to="intela">See more...</Link>
							</div>
							<div className="interactive_learning_slider">
								<div className="slider_section">
									<Slider {...InteractiveSlider} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
										<div className="slide_item">
											<div className="svg_item">
												<img src={intelaHandson} />
											</div>
										</div>
										<div className="slide_item">
											<div className="svg_item">
												<img src={intelaExplore} />
											</div>
										</div>
										<div className="slide_item">
											<div className="svg_item">
												<img src={intelaStepbystep} />
											</div>
										</div>
									</Slider>
								</div>
							</div>
						</div>
					</Container>
				</section>
				<section className="access_to_practice">
					<Container>
						<h2>Sign up for full access to practice questions</h2>
						<div className='access_to_practice_wrapper'>
							<div className='access_to_practice_item'>
								<div className="access_to_practice_box public">
									<div className='access_to_practice_title'>
										<h4>Public</h4>
									</div>
									<div className='access_to_practice_text'>
										<ul>
											<li className='chack'>Public resources</li>
											<li>Weekly practice</li>
											<li>On-demand practice</li>
											<li>Dashboard</li>
										</ul>
									</div>
								</div>
							</div>
							<div className='access_to_practice_item'>
								<div className="access_to_practice_box basic">
									<div className='access_to_practice_title'>
										<h4>Basic</h4>
									</div>
									<div className='access_to_practice_text'>
										<ul>
											<li className='chack'>Public resources</li>
											<li className='chack'>Weekly practice</li>
											<li>On-demand practice</li>
											<li>Dashboard</li>
										</ul>
									</div>
								</div>
								{ !isLoggedIn && (<NavLink to='/signup' className='btn btn_purple'>Sign up</NavLink>)}
								{ !isLoggedIn && (<span>No credit card required</span>)}
							</div>
							<div className='access_to_practice_item'>
								<div className="access_to_practice_box premium">
									<div className='access_to_practice_title'>
										<h4>Premium</h4>
									</div>
									<div className='access_to_practice_text'>
										<ul>
											<li className='chack'>Public resources</li>
											<li className='chack'>Weekly practice</li>
											<li className='chack'>On-demand practice</li>
											<li className='chack'>Dashboard</li>
										</ul>
									</div>
								</div>
								<NavLink to='/pricing' className='btn see_pricing'>See pricing</NavLink>
							</div>
						</div>
					</Container>
				</section>
				<section className="testimonial_slider" id="testimonials-section">
					<Container>
						<div className="testimonial_wrapper">
							{/* <h2>Our Clients</h2> */}
							<Slider {...TestimonialSlider}>
								{
									reviewData.map((e, i) => {
										return <div key={i} className="slide_item">
											<p>{e.tr_text}</p>
											<span>-{' '}
												{e.first_name}{`${e.last_name && ' '}`}
												{e.last_name}, {e.profession}, {e.country}</span>
										</div>;
									})
								}
							</Slider>
						</div>
					</Container>
				</section>
			</div>
			<Footer />
		</div>
	);
};

export default HomePage;
